// This file is compiled by ESBuild, along with any other files present in this directory.
// You're encouraged to place your actual application logic in a relevant structure within
// app/javascript and only use these pack files to reference that code so it'll be compiled.

import Rails from "@rails/ujs";
// window.Rails = Rails;
import * as ActiveStorage from "@rails/activestorage";


// This seems to be important since we can't access $ in the remote page responses.
window.$ = jQuery;

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');
jQueryBridget( 'isotope', Isotope, $ );

require('./custom/filter');

require('./custom/multiSelect');
// Home page video
require('./custom/modal-video');
// Nav Buttons
require('./custom/mobile-nav-button');

Rails.start();
ActiveStorage.start();



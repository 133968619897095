// Multi Select Dropdown
// -----------------------------------
(function($){
  $.fn.multiselect = function() {

    var selector = this;
    var options = $.extend({
          onChange: function() {}
      }, arguments[0] || {});

    activate();

    /////////

    function activate() {

      //events
      $(selector).find('.select-title').on('click', function(e) {
        $(this).parent().find('.select-options').toggle();
      });

      $(selector).find('input[type="checkbox"]').change(function(e){
        options.onChange.call(this);
      });

    }
  };

}(jQuery));

$.ready(function() {
  $('.select-list').multiselect({
    onChange: updateTable
  });
});

function updateTable() {
  var checkboxValue = $(this).val();
  var isChecked = $(this).is(':checked');
}

$(window).on('load', function() {
  //open-close navigation on touch devices
  $('.touch .cd-nav-trigger').on('click', function () {
    $('.touch #cd-vertical-nav').toggleClass('open');
  });

  // close navigation on touch devices when selectin an elemnt from the list
  $('.touch #cd-vertical-nav a').on('click', function () {
    $('.touch #cd-vertical-nav').removeClass('open');
  });


  //open-close navigation on No touch devices
  $('.no-touch .cd-nav-trigger').on('click', function () {
    $('.no-touch #cd-vertical-nav').toggleClass('open');
  });

  //close navigation on No touch devices when selectin an elemnt from the list
  $('.no-touch #cd-vertical-nav a').on('click', function () {
    $('.no-touch #cd-vertical-nav').removeClass('open');
  });
});
